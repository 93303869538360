import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

export default {

  getAll(ctx) {
    ctx.communityStrings = [];
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }
    if (ctx.sort_by !== undefined && typeof ctx.sort_by === 'object' && Object.keys(ctx.sort_by) !== 0) {
      params.sort_by = JSON.parse(JSON.stringify(ctx.sort_by))
    }
    ctx.$store.commit('loadingSNMPCommunityStringsBegin');
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/community`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));

          ctx.communityStrings = listFromResponse;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingSNMPCommunityStringsEnd');
      },
      (err) => {
        ctx.$store.commit('loadingSNMPCommunityStringsEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  update(ctx) {
    ctx.$store.commit('updateSNMPCommunityStringsReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.updatedCommunityString));
    const updatedgId = updated.id;

    const updateQuery = {
      action: 'U',
      items: { [updatedgId]: updated }
    };

    Vue.axios.post(`${API_URL || ''}/api/snmp_management/community`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPCommunityStrings.notificationUpdatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('updateSNMPCommunityStringsReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateSNMPCommunityStringsReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          VueNotifications.error({message: ctx.$t('SNMP.SNMPCommunityStrings.communityStringAlreadyMaybeExists')});
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  create(ctx) {
    ctx.$store.commit('createSNMPCommunityStringsReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.updatedCommunityString));

    const createQuery = {
      action: 'C',
      items: { 0: { ...updated } }
    };
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/community`, createQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPCommunityStrings.notificationCreatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        if (typeof response.data === 'string' && response.data.includes('duplicate')) {
          VueNotifications.error({message: ctx.$t('SNMP.SNMPCommunityStrings.communityStringAlreadyExists')});
          ctx.getAll();
        }
        ctx.closeModal();
        ctx.$store.commit('createSNMPCommunityStringsReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('createSNMPCommunityStringsReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  delete(ctx) {
    ctx.$store.commit('deleteSNMPCommunityStringsReqBegin');
    const deleted = JSON.parse(JSON.stringify(ctx.updatedCommunityString));

    const updateQuery = {
      action: 'D',
      items: { [deleted.id]: {} }
    };
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/community`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success' || response.status === 200) {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPCommunityStrings.notificationDeletedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('deleteSNMPCommunityStringsReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('deleteSNMPCommunityStringsReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },


};
