<template>
  <div style="position: relative" class="w-100 d-flex flex-column">
    <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
    <div class="d-flex w-100 flex-column justify-content-end align-items-end mb-1">
      <WButton info sm
        v-if="!isDisable"
        @click.stop.prevent="addButtonClickHandler"
        :disabled="isDisable"
      >
        {{ $t('SNMP.add') }}
      </WButton>
    </div>
    <div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t('SNMP.SNMPCommunityStrings.communityName') }}</th>
            <th>{{ $t('SNMP.SNMPCommunityStrings.accessMode') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="communityString in communityStrings"
            :key="communityString.id"
            @click.stop.prevent="tableStringClickHandler(communityString)"
          >
            <td>{{ communityString.community_name }}</td>
            <td>{{ getCommunityStringModeForDisplayWithI18n(communityString.access_mode) }}</td>
          </tr>
          <tr v-if="!communityStrings.length">
            <td colspan="4" class="no-data-row">
              <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal
      v-if="isModalOpen"
      :backdrop="false"
      :title="
        isNew
          ? this.$t('SNMP.SNMPCommunityStrings.addCommunityStringModalHeader')
          : this.$t('SNMP.SNMPCommunityStrings.editCommunityStringModalHeader')
      "
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">
        {{
          isNew
            ? this.$t('SNMP.SNMPCommunityStrings.addCommunityStringModalHeader')
            : this.$t('SNMP.SNMPCommunityStrings.editCommunityStringModalHeader')
        }}
      </h4>
      <!--      name-->
      <div class="form-group">
        <label class="required-mark" for="snmp-community-strings-name">
          {{ $t('SNMP.SNMPCommunityStrings.communityName') }}:
        </label>
        <input
          data-vv-validate-on="none"
          @input="removeValidationErrorByName('snmp-community-strings-name')"
          :data-vv-as="$t('SNMP.SNMPCommunityStrings.communityName')"
          v-validate="'required'"
          type="text"
          :disabled="isDisable"
          v-model="updatedCommunityString.community_name"
          name="snmp-community-strings-name"
          id="snmp-community-strings-name"
          :placeholder="$t('SNMP.SNMPCommunityStrings.communityName')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-community-strings-name') }"
        />
        <span v-show="errors.has('snmp-community-strings-name')" class="help is-danger">
          {{ errors.first('snmp-community-strings-name') }}
        </span>
      </div>
      <!--     access mode-->
      <div class="form-group">
        <label for="snmp-community-strings-access-mode">{{ $t('SNMP.SNMPCommunityStrings.accessMode') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPCommunityStrings.accessMode')"
          v-validate="'required'"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedCommunityString.access_mode"
          name="snmp-community-strings-access-mode"
          id="snmp-community-strings-access-mode"
          :placeholder="$t('SNMP.SNMPCommunityStrings.accessMode')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-community-strings-access-mode') }"
        >
          <option :key="mode" v-for="mode in Object.values(communityStringModes)" :value="mode">
            {{ getCommunityStringModeForDisplayWithI18n(mode) }}
          </option>
        </select>
        <span v-show="errors.has('snmp-community-strings-access-mode')" class="help is-danger">
          {{ errors.first('snmp-community-strings-access-mode') }}
        </span>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton success outline
          customClass="mr-1"
          v-if="!isDisable"
          @click="modalSaveButtonHandler"
          :disabled="errors.any()"
        >
          <span>{{ isNew ? $t('general.create') : $t('general.save') }}</span>
        </WButton>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNew && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="modalCancelButtonHandler"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="closeDeleteDialogHandler.bind(this)"
      :handle-delete="deleteDialogDeleteHandler.bind(this)"
      target-text=""
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import DeleteDialog from '../../../../components/delete-dialog.vue';
import snmpCommunityStringsService from '../../../../services/Administration/SNMP/snmpCommunityStringsService';

// const COMMUNITY_STRING_MODES = ['read', 'read/write'];

const COMMUNITY_STRING_MODES = {
  READ: 'read',
  'READ/WRITE': 'read/write'
}



export default {
  name: 'SNMPCommunityStrings',
  components: { Modal, DeleteDialog },
  data() {
    return {
      sort_by: {
        field: 'community_name',
        order: 1
      },
      isDeleteOpen: false,
      isModalOpen: false,
      isNew: false,
      communityStrings: [
      ],
      updatedCommunityString: {},
      newCommunityString: {
        community_name: '',
        access_mode: COMMUNITY_STRING_MODES.READ
      }
    };
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    communityStringModes() {
      return COMMUNITY_STRING_MODES;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isShowOverPageSpinner() {
      return this.$store.state.loadingSNMPCommunityStringsRequestExecuting ||
        this.$store.state.updateSNMPCommunityStringsRequestExecuting ||
        this.$store.state.createSNMPCommunityStringsRequestExecuting ||
        this.$store.state.deleteSNMPCommunityStringsRequestExecuting;
    }
  },
  methods: {
    getCommunityStringModeForDisplayWithI18n(mode) {
      try{
        if(mode === COMMUNITY_STRING_MODES.READ){
          return this.$t('SNMP.SNMPCommunityStrings.modes.read')
        } else if (mode === COMMUNITY_STRING_MODES["READ/WRITE"]){
          return this.$t('SNMP.SNMPCommunityStrings.modes.read/write')
        }
        return mode
      }catch (e) {
        return mode
      }
    },
    removeValidationErrorByName(errorName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    addButtonClickHandler() {
      // console.log('click add button');
      this.updatedCommunityString = JSON.parse(JSON.stringify(this.newCommunityString));
      this.openNewModal();
    },
    tableStringClickHandler(communityString) {
      this.updatedCommunityString = JSON.parse(JSON.stringify(communityString));
      this.openEditModal();
    },
    clearUpdated() {
      this.updatedCommunityString = JSON.parse(JSON.stringify(this.newCommunityString));
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openModal() {
      this.isModalOpen = true;
    },
    openNewModal() {
      this.isNew = true;
      this.openModal();
    },
    openEditModal() {
      this.isNew = false;
      this.openModal();
    },
    modalSaveButtonHandler() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.isNew) {
              snmpCommunityStringsService.create(this);
            } else {
              snmpCommunityStringsService.update(this);
            }
            this.closeModal();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    modalCancelButtonHandler() {
      this.clearUpdated();
      this.closeModal();
    },
    closeDeleteDialogHandler() {
      this.isDeleteOpen = false;
    },
    deleteDialogDeleteHandler() {
      snmpCommunityStringsService.delete(this)
      this.closeDeleteDialogHandler();
      this.closeModal();
    },
    getAll() {
      snmpCommunityStringsService.getAll(this);
    }
  },
  created() {
    this.getAll()
  }
};
</script>

<style scoped></style>
